<template>
  <section>
    <custom-breadcrumb
        :pageTitle="$t('Educational Resources')"
        :breadcrumb="breadcrumb"
      ></custom-breadcrumb>
    <div class="box">
        <div class=" three my-card cursor-pointer text-center ">
            <b-link :to="{name : 'tutorials'}">

                <img src="@/assets/images/teaching-resources/tutoriales.png" 
                    alt="" 
                    class="card-img-top mt-1">
                <div class="my-card-body">
                    <h4>{{$t('resources.tutorials')}}</h4>
                    <p class="text-center">{{$t('resources.tutorials_desc')}}</p> 
                </div>
            </b-link>
        </div>
        <div class=" three my-card cursor-pointer text-center ">
            <b-link :to="{name : 'guides'}">
                <img src="@/assets/images/teaching-resources/guias.png" 
                    alt="" 
                    class="card-img-top mt-1">
                <div class="my-card-body">
                    <h4>{{ $t('resources.guides')}}</h4>
                    <p class="text-center">{{ $t('resources.guides_desc')}}</p> 
                </div>
            </b-link>
        </div>
        <div class=" three my-card cursor-pointer text-center ">
            <b-link :to="{name : 'catalogs'}">
                <img src="@/assets/images/teaching-resources/catalogo.png" 
                    alt="" class="card-img-top mt-1">
                <div class="my-card-body">
                    <h4>{{$t('resources.catalogs')}}</h4>
                    <p class="text-center">{{$t('resources.catalogs_desc')}}</p> 
                </div>
            </b-link>
        </div>
        
    </div>
  </section>
</template>

<script>
import { BLink } from 'bootstrap-vue'
import CustomBreadcrumb from '@/views/components/breadcrumb/CustomBreadcrumb.vue'

export default {
    components:{
        BLink,
        CustomBreadcrumb
    },
    data(){
        return {
            breadcrumb:[],
        }
    }
}
</script>

<style>
.box {
    /* height: 50vh; */
    display: flex;
    flex-direction: row;

    flex-wrap: wrap;
    align-items: stretch;
    justify-content: space-around;

}
.three {
    flex: 1 1 250px;
    margin: 5px;
    max-width: 500px;
}

.cursor-pointer{
    cursor: pointer;
}
.my-card{
    border: none;
    margin-bottom: 2rem;
    box-shadow: 0 4px 24px 0 rgba(34,41,47,.1);
    background-color: #fff;
    background-clip: border-box;
    /* border: 1px solid rgba(34,41,47,.125); */
    border-radius: 0.428rem;
    transition: all .3s ease-in-out,background 0s,color 0s, border-color 0s;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
}
.card-img-top{
        background-color: #fcfcfc;
        border-top-left-radius: calc(0.428rem - 1px);
    border-top-right-radius: calc(0.428rem - 1px);
        border-style: none;
        flex-shrink: 0;
    width: 100%;
        vertical-align: middle;
}
.my-card-body{
    padding: 1.5rem;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-height: 1px;
}
</style>